import { createConfig, http } from "wagmi";
import {
    polygon,
    bsc,
    mainnet,
    arbitrum,
    sepolia,
    arbitrumSepolia,
    Chain,
} from "wagmi/chains";
import { getDefaultConfig } from "connectkit";


const mantleTestnet : Chain = {
    id: 5003,
    name: 'Mantle Tesnet',
    nativeCurrency: { name: 'tMantle', symbol: 'MNT', decimals: 18 },
    rpcUrls: {
      default: {
        http: ['https://rpc.sepolia.mantle.xyz'],
      },
    },
    blockExplorers: {
      default: {
        name: 'Mantlescan',
        url: 'https://sepolia.mantlescan.xyz/',
        apiUrl: 'https://sepolia.mantlescan.xyz/api',
      },
    },
    contracts: {
      multicall3: {
        address: '0xca11bde05977b3631167028862be2a173976ca11',
        blockCreated: 7654707,
      },
    },
    testnet:true
}


const mantleMainnet : Chain = {
    id: 5000,
    name: 'Mantle Mainet',
    nativeCurrency: { name: 'Mantle', symbol: 'MNT', decimals: 18 },
    rpcUrls: {
      default: {
        http: ['https://rpc.mantle.xyz/'],
      },
    },
    blockExplorers: {
      default: {
        name: 'Mantlescan',
        url: 'https://mantlescan.xyz/',
        apiUrl: 'https://mantlescan.xyz/api',
      },
    },
    contracts: {
      multicall3: {
        address: '0xca11bde05977b3631167028862be2a173976ca11',
        blockCreated: 7654707,
      },
    },
    testnet:false
}
export const SupportedChains = [mantleMainnet,mantleTestnet];

export const config = createConfig(
    getDefaultConfig({
        // Required API Keys
        walletConnectProjectId: "5c9adeb706990ce6cabec63da61270fa",
        //Chains
        cacheTime: 10_000,
        chains: [mantleMainnet,mantleTestnet],
        transports: {
            // RPC URL for each chain
            [sepolia.id]: http(
                `https://eth-sepolia.g.alchemy.com/v2/_8hM_H2lFo-7ub_l5x8x01AhpdUMkRJm`,
            ),
            [arbitrumSepolia.id]: http(
                `https://arbitrum-sepolia.blockpi.network/v1/rpc/f3144180d974705270447c0712fc667a4ceee981`,
            ),
            [arbitrum.id]: http(
                `https://arb-mainnet.g.alchemy.com/v2/pjImOtW83VEcpMWAlYFRXmV51qJqrIni`,
            )
        },
        // Required
        appName: "Solid Dungeon",
        batch: {
            multicall: true,
        },
        // Optional
        appDescription: "solid dungegon - game",
        appUrl: "https://family.co", // your app's url
        appIcon: "https://family.co/logo.png", // your app's icon, no bigger than 1024x1024px (max. 1MB)
    })
);
