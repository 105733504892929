// MUI
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import useTheme from '@mui/material/styles/useTheme';
// CUSTOM COMPONENTS
import Footer from './Footer';
import Header from './Header';
// CUSTOM UTILS METHOD
import { isDark } from 'utils/constants';
import { Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
export default function MainLayout({
  children
}) {
  return <Box sx={{ background: 'url("/static/layout-bg.png") 50% 0% no-repeat', backgroundSize: 'cover' }}>
    <Container maxWidth="xl">
      <Stack px={{ md: 0, lg: 5 }}>
        <Header />
        <Outlet />
      </Stack>
    </Container>
  </Box>;
}