import { Box, Stack } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import $ from 'jquery'

export const Map = () => {
    const segment = 0.38
    const [mapInnerWidth, setMapInnerWidth] = useState(0)
    const mapRef = useRef()


    useEffect(() => {
        if (mapRef.current) {
            setMapInnerWidth($(mapRef.current)[0].offsetWidth)
        }
    }, [mapRef.current])
    return <Stack width={'100%'} sx={{ position: 'relative' }}>
        <img ref={mapRef} src="/static/map.png" style={{ width: '100%' }} />
        <Stack sx={{
            width: `${segment * 24 * mapInnerWidth / 10}px`,
            height: `${segment * 13 * mapInnerWidth / 10}px`,
            position: 'absolute',
            bottom: `${segment * mapInnerWidth / 10}px`,
            left: `${segment * mapInnerWidth / 24}px`,
            zIndex: 1
        }}>
            <Character size={segment * mapInnerWidth / 10} info={{
                name: 'char-3'
            }} />
            <Character size={segment * mapInnerWidth / 10} info={{
                name: 'char-1'
            }} />
            <Character size={segment * mapInnerWidth / 10} info={{
                name: 'char-2'
            }} />
        </Stack>
    </Stack>
}


const obstacles = [
    '5:3', '5:4', '5:5', '5:6', '5:7', '5:8', '5:9',
    '6:3', '7:3', '8:3', '9:3', '10:3',
    '10:1', '10:2',
    '11:1', '12:1', '13:1', '14:1', '15:1', '16:1', '17:1', '18:1', '19:1', '20:1', '21:1',
    '22:1', '22:2', '22:3', '22:4', '22:5', '22:6', '22:7', '22:8', '22:8', '22:10', '22:11', '22:12',
    '6:9', '7:9', '8:9', '9:9', '10:9',
    '10:10', '10:11', '10:12', '10:13',
]
const directions = ['left', 'up', 'right', 'down']
const handleCheckObstacle = (x, y) => {
    const temp = `${x}:${y}`
    return Boolean(obstacles.includes(temp)) === true ? 0 : 1
}

const randomNumber = (min, max) => {
    return parseInt(Math.random() * (max - min) + min);
}


const handleGetDirection = async ([x, y]) => {
    const available = [
        handleCheckObstacle(Math.max(x - 1, 0), y),
        handleCheckObstacle(x, Math.max(y - 1, 0)),
        handleCheckObstacle(Math.min(x + 1, 24), y),
        handleCheckObstacle(x, Math.min(y + 1, 13))
    ] //[left,up,right,down]
    let direction = 0
    let directionType = 0
    while (direction === 0) {
        directionType = randomNumber(0, 4)
        direction = available[directionType]
    }
    return directionType
}

const handleGetNewPosition = ([x, y], type) => {
    switch (type) {
        case 0: return [x - 1, y]
        case 1: return [x, y - 1]
        case 2: return [x + 1, y]
        case 3: return [x, y + 1]
    }
    return [x, y]
}


const Character = ({ info, size }) => {
    const [position, setPosition] = useState([13, 7])
    const [moving, setMoving] = useState('')

    const asyncFunc = async ()=>{
        if (moving === '') {
            const direction = await handleGetDirection(position)
            const sleep = randomNumber(1, 5)
            setTimeout(() => {
                const newPosition = handleGetNewPosition(position, direction)
                const positionX = Math.min(Math.max(newPosition[0], 0), 24)
                const positionY = Math.min(Math.max(newPosition[1], 0), 13)
                setPosition([positionX, positionY])
                setMoving(directions[direction])
                setTimeout(() => {
                    setMoving('')
                }, 1000);
            }, sleep * 1000);
        }
    }
    useEffect(()=>{
        asyncFunc()
    }, [moving])

    return <Stack sx={{
        position: 'absolute',
        transition: moving ? 'all 1s linear' : '',
        left: `${position[0] * size + size / 4}px`,
        top: `${position[1] * size}px`,
        transform: `scale(1.5) translateY(-${size / 4}px)`,
        zIndex: position[1] + 10
    }}>
        <img
            src={`images/gifs/${info.name}-${moving ? moving : 'stand'}.gif`}
            width={size}
            height={size}
        />
    </Stack>
}