import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import RTL from "components/rtl";
import { routes } from "./routes";
import { createCustomTheme } from "./theme";
import "./i18n";
import { AuthProvider } from "contexts/auth0Context";
import { Toaster } from "react-hot-toast";
import { connector } from "./connector";
import { useEffect } from "react";
import useSettings from "./hooks/useSettings";
import useUserData from "./hooks/useUserData";
import ReactGA from "react-ga4";
import Web3Provider from './web3/Web3Provider'
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { deserialize, serialize } from "wagmi";

// if (process.env.REACT_APP_IS_LOCAL !== "true") {
ReactGA.initialize("G-NQCE4W27NZ" || "DUMMY");
// }

export default function App() {
  const { settings } = useSettings();
  const { userData } = useUserData();
  const theme = createCustomTheme(settings);
  const router = createBrowserRouter(routes());
  useEffect(() => {
    connector.restoreConnection();
  }, []);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        gcTime: 1_000 * 60 * 60 * 24, // 24 hours
      },
    },
  });

  const persister = createSyncStoragePersister({
    serialize,
    storage: window.localStorage,
    deserialize,
  });
  return (
  <PersistQueryClientProvider
    client={queryClient}
    persistOptions={{ persister }}
  >
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
          <Web3Provider>
            <AuthProvider>
              <RTL>
                <CssBaseline />
                <RouterProvider router={router} />
                <Toaster />
              </RTL>
            </AuthProvider>
            </Web3Provider>
      </ThemeProvider>
    </LocalizationProvider>
    </PersistQueryClientProvider>
  );
}
