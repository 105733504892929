import { Box, Divider, OutlinedInput, Stack, TextField, Typography, useTheme } from "@mui/material"
import { Map } from "../../page-sections/caves/Map"
import FlexBetween from "../../components/flexbox/FlexBetween"
import { PrimaryButton } from "../../components/buttons/PrimaryButton"
import FlexRowAlign from "../../components/flexbox/FlexRowAlign"
import Modal from "../../components/modal/Modal"
import { useState } from "react"
import { SecondaryButton } from "../../components/buttons/SecondaryButton"
import { TertiaryButton } from "../../components/buttons/TertiaryButton"

export const Caves = () => {
    const theme = useTheme()
    return <Stack alignItems={'center'} py={10} spacing={5}>
        <Stack width={'calc(100vw - 20px)'} maxWidth={600}>
            <Map />
        </Stack>
        <Stack width={'100%'}>
            <Typography variant="h5">Global Caves State:</Typography>
            <Typography>Total Combating Hero: <span style={{ color: theme.palette.primary.main }}>28,949</span></Typography>
            <Typography>Total Rating Rate: <span style={{ color: theme.palette.primary.main }}>1,328,405.37 $SDG/ H</span></Typography>
        </Stack>
        <Stack width={'100%'} spacing={2}>
            <FlexBetween >
                <Stack>
                    <Typography variant="h5">Hero In Caves (0)</Typography>
                    <Typography color={'text.secondary'}>Heroes in Caves will earn $DGG rewards.</Typography>
                    <Typography>Total Profit Rate: <span style={{ color: theme.palette.primary.main }}>0.00 $SDG / H</span></Typography>
                </Stack>
                <PrimaryButton sx={{ height: 40, width: 200, color: "#000", fontSize: '1.2rem' }}>All Hero Leave Cave</PrimaryButton>
            </FlexBetween>
            <FlexRowAlign justifyContent='flex-start' gap={2} sx={{
                flexWrap: 'wrap',
                width: 'fit-content',
                '&:hover': {
                    '& .item:not(:hover)': {
                        opacity: 0.5,
                        filter: 'blur(3px)'
                    }
                }
            }}>
                <EnterHero />
                <EnterHero />
                <EnterHero />
            </FlexRowAlign>
        </Stack>
        <Stack width={'100%'} spacing={2}>
            <FlexBetween >
                <Typography variant="h5">My Hero (0)</Typography>
                <PrimaryButton sx={{ height: 40, width: 200, color: "#000", fontSize: '1.2rem' }}>All Hero Enter Cave</PrimaryButton>
            </FlexBetween>
            <FlexRowAlign justifyContent='flex-start' gap={2} sx={{
                flexWrap: 'wrap',
                width: 'fit-content',
                '&:hover': {
                    '& .item:not(:hover)': {
                        opacity: 0.5,
                        filter: 'blur(3px)'
                    }
                }
            }}>
                <ReadyHero />
                <ReadyHero />
                <ReadyHero />
                <ReadyHero />
                <ReadyHero />
                <ReadyHero />
            </FlexRowAlign>
        </Stack>
    </Stack>
}



const Hero = ({ data, enterCave }) => {
    const theme = useTheme()
    return <Stack className="item">
        <Stack
            sx={{
                position: 'relative', cursor: 'pointer'
            }}>
            <img src="/images/box-bg-7.png" style={{ width: 150, height: 150 }} />
            <img src="/images/char-dg.png" style={{ width: '100%', height: '100%', position: 'absolute', top: 0, padding: '3rem 2.5rem 1.6rem', objectFit: 'contain' }} />
            <FlexBetween sx={{ position: 'absolute', width: "100%", top: '12px', padding: "0px 1rem" }}>
                <Typography>LV.1</Typography>
                <Box flexGrow={1} />
                <img src="/images/sword.png" style={{ width: 15, height: 15, objectFit: 'contain' }} />
                <Typography>1826</Typography>
            </FlexBetween>
            <Typography variant="h5" sx={{ position: 'absolute', width: "100%", top: '30px', padding: "0px 1rem", color: '#9AAFC5' }}>N</Typography>
            <Typography color={'text.secondary'} variant="caption" sx={{ position: 'absolute', width: "100%", bottom: '10px', padding: "0px 1rem", fontWeight: 500 }}>ID: 565d83</Typography>
        </Stack>
        {
            enterCave && <>
                <Typography textAlign={'center'} mt={1}>00:00:46</Typography>
                <FlexRowAlign gap={1}>
                    <Typography textAlign={'center'} color={theme.palette.primary.main}>00:00:46</Typography>
                    <img src="/images/coin.png" style={{ width: 15, height: 15, objectFit: 'contain' }} />
                </FlexRowAlign>
            </>
        }
    </Stack>
}

const EnterHero = ({ data }) => {
    const [showDetail, setShowDetail] = useState(false)
    const theme = useTheme()
    return <>
        <Stack onClick={() => setShowDetail(true)}>
            <Hero data={data} enterCave={true} />
        </Stack>
        <Modal open={showDetail} onClose={() => setShowDetail(false)}>
            <Stack width={'100%'} alignItems={'center'} spacing={3}>
                <Typography variant="h5">HERO DETAIL</Typography>
                <FlexBetween gap={4} >
                    <Box sx={{ transform: 'translateX(-2rem)' }}>
                        <Hero data={data} />
                    </Box>
                    <Stack >
                        <Typography noWrap variant="h6">Identity: Dwarven Glint</Typography>
                        <Typography>Rarity: N</Typography>
                        <Typography>Lv: 1</Typography>
                        <Typography>Rating: 1,118</Typography>
                        <Typography color={'text.secondary'}>ID: 565d83</Typography>
                        <Stack mt={2} px={1.2} spacing={1}>
                            <SecondaryButton sx={{ height: 35, fontWeight: 500, fontSize: '1.2rem' }}>
                                Leave Dugeon Caves
                            </SecondaryButton>
                            <FlexRowAlign gap={1}>
                                <img src="/images/coin.png" style={{ width: 15, height: 15, objectFit: 'contain' }} />
                                <Typography textAlign={'center'} color={theme.palette.primary.main}>14,999 $SDG / hour</Typography>
                            </FlexRowAlign>
                        </Stack>
                    </Stack>
                </FlexBetween>
                <Typography textAlign={'center'} color={'#9AAFC5'}>By purchasing with SUI, it will be swapped into $DGG first, and then the purchase will be completed without any additional fees</Typography>
            </Stack>
        </Modal>
    </>
}

const ReadyHero = ({ data }) => {
    const [showDetail, setShowDetail] = useState(false)
    const theme = useTheme()
    return <>
        <Stack onClick={() => setShowDetail(true)}>
            <Hero data={data} />
        </Stack>
        <Modal open={showDetail} onClose={() => setShowDetail(false)}>
            <Stack width={'100%'} alignItems={'center'} spacing={3}>
                <Typography variant="h5">HERO DETAIL</Typography>
                <FlexBetween gap={4} >
                    <Box sx={{ transform: 'translateX(-2rem)' }}>
                        <Hero data={data} />
                    </Box>
                    <Stack >
                        <Typography noWrap variant="h6">Identity: Dwarven Glint</Typography>
                        <Typography>Rarity: N</Typography>
                        <Typography>Lv: 1</Typography>
                        <Typography>Rating: 1,118</Typography>
                        <Typography color={'text.secondary'}>ID: 565d83</Typography>
                        <FlexBetween gap={5} mt={2}>
                            <Stack>
                                <Typography >Upgrade to Lv.2</Typography>
                                <Typography color={theme.palette.primary.main}>14,999 $SDG</Typography>
                            </Stack>
                            <SecondaryButton sx={{ height: 40, fontWeight: 500, fontSize: '1.2rem' }}>
                                Upgrade
                            </SecondaryButton>
                        </FlexBetween>
                    </Stack>
                </FlexBetween>
                <Typography textAlign={'center'} color={'#9AAFC5'}>By purchasing with SUI, it will be swapped into $DGG first, and then the purchase will be completed without any additional fees</Typography>
                <PrimaryButton sx={{ height: 40, width: 250, fontWeight: 700, fontSize: '1.2rem', color: "#000" }}>Enter Dungron Caves</PrimaryButton>
                <Stack alignItems={'center'}>
                    <Typography textAlign={'center'} color={'#9AAFC5'}>Enter Dungeon Caves and start earning $SDG</Typography>
                    <Typography color={theme.palette.primary.main}>10.38 $SDG / hour</Typography>
                </Stack>
                <FlexRowAlign gap={1} sx={{ width: "100%" }}>
                    <Typography sx={{ whiteSpace: 'nowrap' }} variant="h6">List Price</Typography>
                    <img src="/images/coin.png" style={{ width: 15, height: 15, objectFit: 'contain' }} />
                    <OutlinedInput
                        variant="contained"
                        placeholder="Enter a price"
                        size="small"
                        sx={{
                            flexGrow: 1,
                            marginLeft: '1rem',
                            background: "#000",
                            borderRadius: '0px !important',
                            width: '100%',
                            maxWidth: { xs: 'unset', sm: 200 },
                            '& fieldset': { border: 'none !important' },
                            '& input': {
                                color: theme.palette.text.primary
                            }
                        }}
                    />
                    <TertiaryButton sx={{ height: 40, fontWeight: 500, fontSize: '1.2rem', color: "#000", marginLeft: '1.5rem' }}>
                        Marketplace
                    </TertiaryButton>
                </FlexRowAlign>
            </Stack>
        </Modal >
    </>
}
