import { lazy } from "react";
import Loadable from "./Loadable";
import { Marketplace } from "../pages/main/marketplace";
import { Caves } from "../pages/main/caves";

// ALL DASHBOARD PAGES

export const MainRoutes = [
  {
    path: "",
    element: <Marketplace />,
  },
  {
    path: "caves",
    element: <Caves />,
  },
];
