import { Grid, Stack, Typography, useTheme } from "@mui/material"
import { Map } from "../../page-sections/marketplace/Map"
import { Chests } from "./chests"
import FlexBetween from "../../components/flexbox/FlexBetween"
import FlexRowAlign from "../../components/flexbox/FlexRowAlign"

export const Marketplace = () => {
    const theme = useTheme()
    return <Stack alignItems={'center'} py={10} spacing={5}>
        <Typography variant="h2"><span style={{ color: theme.palette.primary.main }}>Fully On-chain</span> Gaming</Typography>
        <Stack width={'calc(100vw - 20px)'} maxWidth={600}>
            <Map />
        </Stack>
        <Chests />
        <Grid container pt={10} px={{ xs: 0, sm: 2, md: 4 }} columnSpacing={2} rowSpacing={4}>
            <Grid item xs={12} sm={6} md={4} lg={3} >
                <FlexRowAlign justifyContent='flex-start' gap={2}>
                    <img src='/static/char-1.png' style={{ height: 40 }} />
                    <Stack>
                        <Typography variant="h6" lineHeight={'25px'}>Market -></Typography>
                        <Typography color={'text.secondary'}>Trade Hero using $DGG</Typography>
                    </Stack>
                </FlexRowAlign>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <FlexRowAlign justifyContent='flex-start' gap={2}>
                    <img src='/static/char-2.png' style={{ height: 40 }} />
                    <Stack>
                        <Typography variant="h6" lineHeight={'25px'}>Leader board -></Typography>
                        <Typography color={'text.secondary'}>Check out the top tier Heroes</Typography>
                    </Stack>
                </FlexRowAlign>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <FlexRowAlign justifyContent='flex-start' gap={2}>
                    <img src='/static/char-3.png' style={{ height: 40 }} />
                    <Stack>
                        <Typography variant="h6" lineHeight={'25px'}>Market -></Typography>
                        <Typography color={'text.secondary'}>Purchase a  treasure chest to draw your unique Hero.</Typography>
                    </Stack>
                </FlexRowAlign>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <FlexRowAlign justifyContent='flex-start' gap={2}>
                    <img src='/static/char-4.png' style={{ height: 40 }} />
                    <Stack>
                        <Typography variant="h6" lineHeight={'25px'}>Invitation -></Typography>
                        <Typography color={'text.secondary'}>Send the invitation code to your friends.</Typography>
                    </Stack>
                </FlexRowAlign>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <FlexRowAlign justifyContent='flex-start' gap={2}>
                    <img src='/static/char-5.png' style={{ height: 40 }} />
                    <Stack>
                        <Typography variant="h6" lineHeight={'25px'}>Territory -></Typography>
                        <Typography color={'text.secondary'}>Manage your Hero army.</Typography>
                    </Stack>
                </FlexRowAlign>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <FlexRowAlign justifyContent='flex-start' gap={2}>
                    <img src='/static/char-6.png' style={{ height: 40 }} />
                    <Stack>
                        <Typography variant="h6" lineHeight={'25px'}>Caves -></Typography>
                        <Typography color={'text.secondary'}>Send your Hero army into caves to earn $DGG.</Typography>
                    </Stack>
                </FlexRowAlign>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <FlexRowAlign justifyContent='flex-start' gap={2}>
                    <img src='/static/char-7.png' style={{ height: 40 }} />
                    <Stack>
                        <Typography variant="h6" lineHeight={'25px'}>Docs -></Typography>
                        <Typography color={'text.secondary'}>Game Instructions.</Typography>
                    </Stack>
                </FlexRowAlign>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <FlexRowAlign justifyContent='flex-start' gap={2}>
                    <img src='/static/char-7.png' style={{ height: 40 }} />
                    <Stack>
                        <Typography variant="h6" lineHeight={'25px'}>PVP -></Typography>
                        <Typography color={'text.secondary'}>After enabling PVP, your Hero can battle other players for extra rewards.</Typography>
                    </Stack>
                </FlexRowAlign>
            </Grid>
        </Grid>
    </Stack>
}