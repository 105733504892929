import * as React from 'react';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import { Stack } from '@mui/material';
import FlexBetween from './flexbox/FlexBetween';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 0,
        position: 'relative',
        backgroundColor: '#38365C',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        minWidth: '100px',
    },
}));

export default function AppSelect({ label, options, onChange, optionRender, sx, defaultValue }) {
    const [currentValue, setCurrentValue] = React.useState(defaultValue || { value: '' });
    const handleChange = (event) => {
        const temp = options?.find(item => item.value === event.target.value)
        setCurrentValue(temp);
        if (onChange)
            onChange(temp)
    };
    return (
        <FormControl variant="standard" sx={{ ...sx }}>
            {
                label && <InputLabel>{label}</InputLabel>
            }
            <Select
                value={currentValue?.value}
                onChange={handleChange}
                input={<BootstrapInput />}
                renderValue={optionRender ? () => optionRender(currentValue) : () => <FlexBetween px={1}>
                    {currentValue?.label}
                </FlexBetween>}
                IconComponent={() => <img src='/static/drop-down.png' style={{ position: 'absolute', width: 13, right: '15px' }} />}
                MenuProps={{
                    sx: {
                        '& .MuiPaper-root': {
                            borderRadius: 0
                        }
                    }
                }}
            >
                {
                    options?.map(option => optionRender
                        ? <MenuItem sx={{ borderRadius: 0 }} key={option.value} value={option.value}>{optionRender(option)}</MenuItem>
                        : <MenuItem sx={{ borderRadius: 0 }} key={option.value} value={option.value}>{option?.label || option?.title}</MenuItem>
                    )
                }
            </Select>
        </FormControl >
    );
}