import MuiModal from '@mui/material/Modal';
// STYLED COMPONENT
import { StyledScrollbar, Wrapper } from './styles';
import { ButtonBase } from '@mui/material';

// ===========================================================================

// ===========================================================================

export default function Modal({
  children,
  open,
  onClose,
  ...props
}) {
  const handleClose = () => {
    if (onClose) onClose()
  }
  return <MuiModal open={open} onClose={onClose} sx={{ maxWidth: 'unset' }}>
    <Wrapper {...props}>
      <ButtonBase onClick={() => handleClose()} sx={{ position: 'absolute', top: '15px', right: '15px', zIndex: 100 }}>
        <img src='/images/close-icon.png' style={{ width: 20, height: 20 }} />
      </ButtonBase>
      <StyledScrollbar>{children}</StyledScrollbar>
    </Wrapper>
  </MuiModal>;
}