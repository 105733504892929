import { ButtonBase } from "@mui/material"

export const TertiaryButton = ({ sx, children, onClick }) => {
    return <ButtonBase onClick={onClick} sx={{
        ...sx,
        minWidth: 70,
        minHeight: 30,
        background: 'url("/static/btn-bg-center-2.png") 50% 50%',
        position: 'relative',
        backgroundSize: "contain",
        zIndex: 10,
        '&:hover': {
            transform: "scale(1.06)"
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 'calc(100% - 2px)',
            height: '100%',
            aspectRatio: 0.5,
            background: 'url("/static/btn-bg-left-2.png") 100% 50% no-repeat',
            backgroundSize: 'contain',
            marginLeft: '-50%',
            zIndex: 0
        },
        '&:after': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 'calc(100% - 2px)',
            height: '100%',
            aspectRatio: 0.5,
            background: 'url("/static/btn-bg-right-2.png") 0% 50% no-repeat',
            backgroundSize: 'contain',
            marginRight: '-50%'
        }
    }}>
        {children}
    </ButtonBase>

}