import { Fragment, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import ListItemButton from "@mui/material/ListItemButton";
import Menu from "@mui/icons-material/Menu";
import useLocation from "hooks/useLocation";
import Link from "components/link";
import MegaMenu from "./menu/MegaMenu";
import MegaMenuList from "./menu/MegaMenuList";
import Scrollbar from "components/scrollbar";
import { Paragraph } from "components/typography";
import { FlexBetween, FlexBox } from "components/flexbox";
import { Stack, Typography, useTheme } from "@mui/material";
import FlexRowAlign from "../../components/flexbox/FlexRowAlign";
import { PrimaryButton } from "../../components/buttons/PrimaryButton";
import { useModal } from "connectkit";
import { ConnectKitButton } from "connectkit";

// COMMON STYLE OBJECT
const LIST_STYLE = {
  gap: 4,
  fontSize: 14,
  fontWeight: 500,
  listStyle: "none",
  alignItems: "center",
  a: {
    transition: "color 300ms",
    ":hover": {
      color: "primary.main",
    },
  },
};
export default function Header() {
  const { pathname } = useLocation();
  const theme = useTheme()
  const [open, setOpen] = useState(false);
  const isMedium = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const isActive = (path) => pathname === path;
  useEffect(() => {
    if (isMedium) setOpen(false);
  }, [isMedium]);
  const { openOnboarding: openConnect } = useModal()

  const menus = [
    { title: 'Marketplace', href: '/' },
    { title: 'Territory', href: '/territory' },
    { title: 'Caves', href: '/caves' },
    { title: 'LeaderBoard', href: '/leader-board' },
    { title: 'Treasure', href: '/treasure' },
    { title: 'Airdrop', href: '/airdrop' },
    { title: 'Invitation', href: '/invitation' },
    { title: 'Docs', href: '/docs' },
  ]

  // FOR LARGE SCREEN DEVICE
  const LARGE_DEVICE_CONTENT = (
    <FlexBox component='nav' sx={LIST_STYLE}>
      {
        menus.map(menu => <Typography component={Link} href={menu.href} sx={{ color: isActive(menu.href) ? theme.palette.primary.main : theme.palette.text.primary, '&:hover': { color: `${theme.palette.primary.main} !important`, opacity: 1 } }}>
          {menu.title}
        </Typography>)
      }
    </FlexBox>
  );

  // FOR SMALL AND MEDIUM SCREEN DEVICE
  const SMALL_DEVICE_CONTENT = (
    <Fragment>
      <Drawer anchor='right' open={open} onClose={() => setOpen(false)}>
        <Scrollbar>
          <List
            disablePadding
            sx={{
              minWidth: 260,
              height: "100%",
            }}>
            <ListItem
              sx={{
                mb: 1,
              }}>
              <img
                src='/static/logo/logo-svg.svg'
                alt='logo'
                width={40}
                height={40}
              />
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton LinkComponent={Link} href='/'>
                Home
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton LinkComponent={Link} href='/components'>
                Components
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton
                LinkComponent='a'
                href='http://Ton-doc.vercel.app/'>
                Documentation
              </ListItemButton>
            </ListItem>

            <ListItem
              sx={{
                mt: 1,
              }}>
              <Button fullWidth>Buy Now</Button>
            </ListItem>
          </List>
        </Scrollbar>
      </Drawer>

      <IconButton
        color='primary'
        onClick={() => setOpen(true)}
        sx={{
          flexShrink: 0,
        }}>
        <Menu />
      </IconButton>
    </Fragment>
  );

  const ACCOUNT_CONTENT = (
    <FlexRowAlign gap={5}>
      <Stack>
        <Typography>{`$SDG Balance 0`}</Typography>
        {/* <Typography lineHeight={1} variant="h5">0</Typography> */}
      </Stack>
      <ConnectKitButton.Custom>
        {({ isConnected, isConnecting, show, hide, truncatedAddress, ensName, chain }) => {
          return (
            <PrimaryButton sx={{ height: 45, width: 200, color: "#000", fontSize: '1.2rem' }} onClick={show}>
              {isConnected ? truncatedAddress : "Connect Wallet"}
            </PrimaryButton>
          );
        }}
      </ConnectKitButton.Custom>
    </FlexRowAlign>
  );
  return (
    <FlexBetween component='header' py={2}>
      <Stack spacing={1} direction={isMedium ? 'column' : 'row-reverse'}>
        <FlexRowAlign gap={1} display={{ xs: 'none', md: 'flex' }} sx={{ justifyContent: 'flex-start' }}>
          <img
            src='/static/logo/logo-svg.svg'
            alt='logo'
            width={35}
            height={35}
          />
          <Typography variant="h5" fontWeight={600} color='text.primary'>
            Solid Dungeon
          </Typography>
        </FlexRowAlign>
        {isMedium ? LARGE_DEVICE_CONTENT : SMALL_DEVICE_CONTENT}
      </Stack>
      {ACCOUNT_CONTENT}
    </FlexBetween>
  );
}
