import { lazy } from "react";
import Loadable from "./Loadable";
import { PublicRoutes } from "./public";
import { Dashboard } from "../pages/mobile/dashboard";
import MainLayout from "../layouts/main/MainLayout";
import { MainRoutes } from "./main-routes";

// GLOBAL ERROR PAGE
const ErrorPage = Loadable(lazy(() => import("pages/404")));
// MOBILE APP

export const routes = () => {
  return [
    {
      path: "/dashboard",
      element: <Dashboard />,
    },
    // INITIAL / INDEX PAGE
    {
      path: "/",
      element: <MainLayout />,
      children: [...MainRoutes],
    },
    // GLOBAL ERROR PAGE
    {
      path: "*",
      element: <ErrorPage />,
    },
    // PAGES ROUTES
    ...PublicRoutes,
  ];
};
