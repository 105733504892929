import { Grid, LinearProgress, Stack, useTheme, Typography, TextField, OutlinedInput, ButtonBase } from "@mui/material"
import FlexBetween from "../../components/flexbox/FlexBetween"
import AppSelect from "../../components/AppSelect"
import Iconify from "../../components/Iconify"
import FlexRowAlign from "../../components/flexbox/FlexRowAlign"
import { Divider } from "antd"
import { PrimaryButton } from "../../components/buttons/PrimaryButton"
import { ConnectKitButton } from "connectkit";
import { useAccount, useConnect } from "wagmi"

export const Chests = () => {
    const theme = useTheme()
    const { isConnected } = useAccount()
    const ChainSupported = [
        { value: 1, id: 5000, name: 'Mantle', chainName: "MNT", icon: <img src="/static/chains/MNT.png" style={{ width: 25, height: 25 }} /> }
    ]
    const CurrencySupported = [
        { value: 1, id: 1, name: 'MNT', chainName: "MNT", icon: <img src="/static/coins/MNT.png" style={{ width: 25, height: 25 }} /> }
    ]
    return <Stack sx={{ width: { xs: '100%', lg: '80%' }, background: { xs: 'none', md: '#0A0B21' } }}>
        <Grid container p={{ xs: 0, md: 10 }} columnSpacing={5}>
            <Grid item xs={12} md={4}>
                <Stack pb={3} height={'100%'} spacing={3} alignItems={'center'} justifyContent={'center'} sx={{ background: theme.palette.background.default }}>
                    <img src="/static/chest.png" style={{ width: '70%', maxWidth: 250 }} />
                    <Stack sx={{ width: '80%', maxWidth: '250px' }} spacing={1}>
                        <LinearProgress variant="determinate" value={40} sx={{ height: '10px', borderRadius: 0, background: "#000", '& .MuiLinearProgress-bar': { background: '#708AA6' } }} />
                        <Typography textAlign={'center'} fontStyle={'italic'} color={'#708AA6'}>671/2000 chests (23%)</Typography>
                    </Stack>
                </Stack>
            </Grid>
            <Grid item xs={12} md={8}>
                <Stack spacing={2}>
                    <FlexBetween gap={1} sx={{ flexWrap: 'wrap' }}>
                        <Stack maxWidth={300}>
                            <Typography>Select Network</Typography>
                            <Typography variant="caption" color={'text.secondary'}>Chest to submit your claim to each ZAP node and assert eligibility for incentives</Typography>
                        </Stack>
                        <AppSelect
                            options={ChainSupported}
                            defaultValue={ChainSupported[0]}
                            sx={{ flexGrow: 1, maxWidth: { xs: 'unset', sm: 200 } }}
                            optionRender={option => <FlexBetween justifyContent="flex-start" pr={2} gap={1} sx={{ maxHeight: '30px' }}>
                                {option?.icon}
                                <Typography>{option?.name}</Typography>
                            </FlexBetween>}
                        />
                    </FlexBetween>
                    <FlexBetween gap={2}>
                        <Typography>Select Currency</Typography>
                        <AppSelect
                            options={CurrencySupported}
                            defaultValue={CurrencySupported[0]}
                            sx={{ flexGrow: 1, maxWidth: { xs: 'unset', sm: 200 } }}
                            optionRender={option => <FlexBetween justifyContent="flex-start" pr={2} gap={1}>
                                {option?.icon}
                                <Typography>{option?.name}</Typography>
                            </FlexBetween>}
                        />
                    </FlexBetween>
                    <FlexBetween>
                        <Typography>1 x Chest</Typography>
                        <FlexRowAlign gap={1} >
                            {CurrencySupported[0]?.icon}
                            <Typography variant="h6" color={theme.palette.primary.main}>0.0353  MNT</Typography>
                        </FlexRowAlign>
                    </FlexBetween>
                    <FlexBetween gap={1} sx={{ flexWrap: 'wrap' }}>
                        <Stack maxWidth={300}>
                            <Typography>Add promo code</Typography>
                            <Typography variant="caption" color={'text.secondary'}>(Only 1 code can be applied per payment)</Typography>
                        </Stack>
                        <OutlinedInput
                            variant="contained"
                            placeholder="Enter code"
                            size="small"
                            sx={{
                                flexGrow: 1,
                                background: "#000",
                                borderRadius: '0px !important',
                                width: '100%',
                                maxWidth: { xs: 'unset', sm: 200 },
                                '& fieldset': { border: 'none !important' },
                                '& input': {
                                    color: theme.palette.text.primary
                                }
                            }}
                        />
                    </FlexBetween>
                    <FlexBetween gap={1} sx={{ flexWrap: 'wrap' }}>
                        <Stack maxWidth={300}>
                            <Typography>Quantity</Typography>
                            <Typography variant="caption" color={'text.secondary'}>(Only 1 code can be applied per payment)</Typography>
                        </Stack>
                        <OutlinedInput
                            variant="contained"
                            placeholder="Enter code"
                            size="small"
                            defaultValue={1}
                            startAdornment={<ButtonBase sx={{ color: theme.palette.text.primary }}><Iconify icon={'openmoji:minus'} /></ButtonBase>}
                            endAdornment={<ButtonBase sx={{ color: theme.palette.text.primary }}><Iconify icon={'openmoji:plus'} /></ButtonBase>}
                            sx={{
                                flexGrow: 1,
                                background: "#000",
                                borderRadius: '0px !important',
                                width: '100%',
                                maxWidth: { xs: 'unset', sm: 200 },
                                '& fieldset': { border: 'none !important' },
                                '& input': {
                                    color: theme.palette.text.primary,
                                    textAlign: 'center',
                                }
                            }}
                        />
                    </FlexBetween>
                    <FlexBetween>
                        <Typography>Your total</Typography>
                        <FlexRowAlign gap={1}>
                            <Typography variant="h6" color={theme.palette.primary.main}>0.0353 MNT</Typography>
                        </FlexRowAlign>
                    </FlexBetween>
                    <FlexRowAlign>
                        {isConnected ?
                            <PrimaryButton sx={{ height: 45, width: 200, color: "#000", fontSize: '1.2rem' }} onClick={() => { }}>
                                Mint </PrimaryButton>
                            : <ConnectKitButton.Custom>
                                {({ isConnected, isConnecting, show, hide, truncatedAddress, ensName, chain }) => {
                                    return (
                                        <PrimaryButton sx={{ height: 45, width: 200, color: "#000", fontSize: '1.2rem' }} onClick={show}>
                                            {isConnected ? truncatedAddress : "Connect Wallet"}
                                        </PrimaryButton>
                                    );
                                }}
                            </ConnectKitButton.Custom>}
                    </FlexRowAlign>
                </Stack>
            </Grid>
        </Grid>
    </Stack>
}